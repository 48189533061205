<template>
    <div class="warp">
        <div class="header">
            <van-nav-bar class="navBar" fixed :placeholder="true" @click-left="goBack">
                <template #left class="left">
                    <van-icon name="arrow-left" size="22" color="#141414" />
                </template>
                <template #title>
                    <span class="title" style="font-size:17px;font-weight:500;">学习中心</span>
                </template>
            </van-nav-bar>
        </div>

        <div class="bodyWarp">
            <div class="summary">
                <div class="title">
                    <img class="icon" src="@/assets/all_Icon/drawable-xxhdpi/icon_horn.png" />
                    <span>Hi~，您已经连续学习 <span class="day">{{ coureStudyObj.continuityStudyDays }}</span>天啦！</span>
                </div>
                <div class="row">
                    <van-row>
                        <van-col span="8">
                            <div>
                                <p class="p1">{{ (coureStudyObj.studyCourseLength/3600).toFixed(2) }}</p>
                                <p class="p2">听课时长/时</p>
                            </div>
                        </van-col>
                        <van-col span="8">
                            <div>
                                <p class="p1">{{ coureStudyObj.answerCount }}</p>
                                <p class="p2">已做题（题）</p>
                            </div>
                        </van-col>
                        <van-col span="8">
                            <div>
                                <p class="p1">{{ coureStudyObj.answerWrongCount }}</p>
                                <p class="p2">错题数（题）</p>
                            </div>
                        </van-col>
                    </van-row>
                </div>
            </div>

            <div class="type">
                <div class="typeList curriculum" @click="goType('curriculum')">
                    <p class="p1">{{ coureStudyObj.mineCourseCount }}</p>
                    <p class="p2">我的课程</p>
                </div>
                <div class="typeList live" @click="goType('live')">
                    <p class="p1">{{ coureStudyObj.mineLiveCount }}</p>
                    <p class="p2">我的直播</p>
                </div>
                <div class="typeList answering" @click="goType('answering')">
                    <p class="p1">{{ coureStudyObj.courseDaYiCount }}</p>
                    <p class="p2">课程答疑</p>
                </div>
                <div class="typeList note" @click="goType('note')">
                    <p class="p1">{{ coureStudyObj.courseNoteCount }}</p>
                    <p class="p2">笔记</p>
                </div>
            </div>

            <div class="drive"></div>

            <div class="tab">
                <span class="tabs" :class="active===1?'active':''" @click="showActive(1)">
                    已购课程
                    <span class="b"></span>
                </span>
                <span class="tabs" :class="active===2?'active':''" @click="showActive(2)">
                    最近浏览
                    <span class="b"></span>
                </span>

                <span class="screen" @click="screen">
                    <img class="icon" src="@/assets/all_Icon/drawable-xxhdpi/icon_screen.png" />
                    筛选
                </span>
            </div>

            <div class="listBrowsing" v-show="active===2">
                <div class="item" v-for="item in listBrowsing" :key="item.id" @click="detial(item)">
                    <img class="left" :src="item.coverPath">
                    <div class="right">
                        <p class="title">{{ item.packageName }}</p>
                        <p class="desc">{{ item.pageageSecondName }}</p>
                        <p class="time" v-if="item.courseDiscountPriceLeftTime">折扣倒计时:{{ formatSeconds(item.courseDiscountPriceLeftTime) }}</p>
                        <p class="footer" :class="item.courseDiscountPriceLeftTime?'':'t'">
                            <template v-if="item.isFree === 1">
                                <span v-if="item.courseDiscountPriceLeftTime" style="text-decoration:line-through;">¥{{ item.coursePrice }}</span>
                                <span v-if="item.courseDiscountPriceLeftTime" style="margin-left:10px">¥{{ item.courseDiscountPrice }}</span>
                                <span v-else>¥{{ item.coursePrice }}</span>
                            </template>
                            <span v-if="item.isFree === 0">免费</span>
                            <span class="btn">{{ item.isFree === 1 ? '购买' : '学习' }}</span>
                        </p>
                    </div>
                </div>
            </div>

            <div class="list" v-show="active===1">
                <div class="item" v-for="(item,i) in list" @click="detial(item)" :key="i">
                    <img class="left" :src="item.coverPath">
                    <div class="right">
                        <p class="title">{{ item.packageName }}</p>
                        <div class="question_cirle">
                            <div class="question_cirle-continer">
                                <div class="question_cirle-yes" :style="setCirleNum(item)"></div>
                            </div>
                            <span>{{ item.studyCourseCount }}/{{ item.classHourCount1 }}课时</span>
                        </div>
                        <p class="footer">
                            <span>截止：{{ item.effectiveEtime }}</span>
                            <span class="btn">继续学习</span>
                        </p>
                    </div>
                </div>
            </div>
        </div>

        <!-- 底部导航 -->
        <tabBar></tabBar>

    </div>
</template>

<script>
import { coureStudy, studyBrowse, studyHasBuy } from "@/api/study_course.js";
import tabBar from '@/components/tabBar' //底部导航 组件

export default {
    components: { tabBar },
    name:'learning',
    data() {
        return {
            listBrowsing: [],
            list: [],
            firstId: '',
            active: 1,
            coureStudyObj: {
                continuityStudyDays: 0,
                answerWrongCount: 0,
                answerCount: 0,
                studyCourseLength: 0,
                courseDaYiCount: 0,
                courseNoteCount: 0,
                mineCourseCount: 0,
                mineLiveCount: 0,
            }
        }
    },
    methods: {
        goBack() {
            this.$router.push('/');
        },
        showActive(val) {
            this.active = val;
        },
        screen() {
            //筛选
            this.$router.push('/directionList');
        },
        goType(type) {
            if (type === 'curriculum') {
                // 我的课程
                this.$router.push('/mycourse');
            } else if (type === 'live') {
                // 我的直播
                this.$router.push('/mylive');
            } else if (type === 'answering') {
                // 课程答疑
                this.$router.push('/curriculumAnswer');
            } else if (type === 'note') {
                // 笔记
                this.$router.push('/noteList');
            }
        },
        setCirleNum(item) {
            let num = item.studyCourseCount / item.classHourCount1 * 100 + "%";
            return {
                "width": num
            }
        },
        formatSeconds(value) {
            //  秒
            let second = parseInt(value)
            //  分
            let minute = 0
            //  小时
            let hour = 0
            //  天
            let day = 0
            //  如果秒数大于60，将秒数转换成整数
            if (second > 60) {
                //  获取分钟，除以60取整数，得到整数分钟
                minute = parseInt(second / 60)
                //  获取秒数，秒数取佘，得到整数秒数
                second = parseInt(second % 60)
                //  如果分钟大于60，将分钟转换成小时
                if (minute > 60) {
                    //  获取小时，获取分钟除以60，得到整数小时
                    hour = parseInt(minute / 60)
                    //  获取小时后取佘的分，获取分钟除以60取佘的分
                    minute = parseInt(minute % 60)
                    //  如果小时大于24，将小时转换成天
                    if (hour > 23) {
                        //    //  获取天数，获取小时除以24，得到整天数
                        day = parseInt(hour / 24)
                        //    //  获取天数后取余的小时，获取小时除以24取余的小时
                        hour = parseInt(hour % 24)
                    }
                }
            }

            let result = '' + parseInt(second) + '秒'
            if (minute > 0) {
                result = '' + parseInt(minute) + '分' + result
            }
            if (hour > 0) {
                result = '' + parseInt(hour) + '小时' + result
            }
            if (day > 0) {
                result = '' + parseInt(day) + '天' + result
            }
            // console.log('result：', result)
            return result
        },
        init() {
            coureStudy().then((res) => {
                if (res.data.code === 0) {
                    this.coureStudyObj = res.data.data;
                }
            });
            studyBrowse({ typeId: this.firstId }).then((res) => {
                if (res.data.code === 0) {
                    this.listBrowsing = res.data.data;
                }
            });
            studyHasBuy({ typeId: this.firstId }).then((res) => {
                if (res.data.code === 0) {
                    this.list = res.data.data;
                }
            });
        },
        detial(val) {
            //跳转详情
            if (this.active === 1) {// 已购
                let id = val.id;
                let source = "souce传递"
                this.$router.push({path: '/meallist/'+id + '/' + source});
                // this.$router.push({
                //     path: "/meallist",
                //     query: {
                //         id: val.id
                //     }
                // })


            } else {//浏览
                // this.$router.push({ path: `/classCenter?coursePackageId=${val.id}` })
                this.$router.push('/ke/' + val.id)
            }
            console.log(val);
        }
    },
    created() {
        if(this.$route.params.from == 'direction'){
            this.firstId = window.localStorage.getItem('firstId');
        }
        this.init();
    },
    
}
</script>

<style lang="less" scoped>
.header {
    font-size: 17px;
    font-weight: 500;
    color: #141414;
    line-height: 24px;
}

/deep/ .van-nav-bar__title {
    font-size: 17px;
    font-weight: 500;
    color: #141414;
}

/deep/ .header .van-icon-arrow-left {
    color: #000;
}

.bodyWarp {
    margin-top: 14px;
    height: calc(100vh - 78px);

    .summary {
        width: 346px;
        height: 119px;
        border-radius: 5px;
        margin: 14px 14px 18px;
        background: url("../../assets/all_Icon/drawable-xxhdpi/study_header_bg.png")
            no-repeat 100% 100%/100%;

        .title {
            height: 23px;
            font-size: 13px;
            font-weight: 400;
            color: #666666;
            line-height: 18px;
            margin: 0 0 19px 15px;
            padding-top: 15px;

            .icon {
                display: inline-block;
                width: 12px;
                height: 14px;
                margin-right: 9px;
            }

            .day {
                font-size: 18px;
                font-weight: 500;
            }
        }

        .row {
            text-align: center;

            .p1 {
                height: 25px;
                font-size: 18px;
                font-weight: 500;
                color: #333333;
                line-height: 25px;
            }

            .p2 {
                height: 18px;
                font-size: 13px;
                font-weight: 400;
                color: #666666;
                line-height: 18px;
            }
        }
    }

    .type {
        width: 346px;
        margin: 0 auto 18px;

        .typeList {
            width: 82px;
            height: 67px;
            border-radius: 5px;
            display: inline-block;
            text-align: center;

            .p1 {
                height: 27px;
                font-size: 19px;
                font-weight: 400;
                line-height: 27px;
                margin-top: 11px;
            }

            .p2 {
                height: 17px;
                font-size: 12px;
                font-weight: 400;
                line-height: 17px;
            }
        }

        .curriculum,
        .live,
        .answering {
            margin-right: 6px;
        }

        .curriculum {
            .p1 {
                color: #ed5959;
            }

            .p2 {
                color: #d47474;
            }

            background: url("../../assets/all_Icon/drawable-xxhdpi/study_course_bg.png")
                no-repeat 100% 100%/100%;
        }

        .live {
            .p1 {
                color: #2470f4;
            }

            .p2 {
                color: #5787eb;
            }

            background: url("../../assets/all_Icon/drawable-xxhdpi/study_live_bg.png")
                no-repeat 100% 100%/100%;
        }

        .answering {
            .p1 {
                color: #5aa65b;
            }

            .p2 {
                color: #8aae84;
            }

            background: url("../../assets/all_Icon/drawable-xxhdpi/study_answer_bg.png")
                no-repeat 100% 100%/100%;
        }

        .note {
            .p1 {
                color: #de5bc0;
            }

            .p2 {
                color: #d55eb0;
            }

            background: url("../../assets/all_Icon/drawable-xxhdpi/study_note_bg.png")
                no-repeat 100% 100%/100%;
        }
    }

    .drive {
        height: 7px;
        background: #f8f8f8;
    }

    .tab {
        padding: 20px 14px;
        position: relative;

        .tabs {
            width: 58px;
            height: 20px;
            font-size: 14px;
            font-weight: 500;
            color: #666666;
            line-height: 20px;
            margin-right: 12px;
        }

        .active {
            width: 69px;
            height: 24px;
            font-size: 17px;
            font-weight: 500;
            color: #333333;
            line-height: 24px;
            text-align: center;
            position: relative;
            padding-bottom: 10px;

            .b {
                position: absolute;
                bottom: 0;
                left: 20px;
                display: inline-block;
                width: 29px;
                height: 4px;
                background: #5d7dff;
                border-radius: 2px;
            }
        }

        .screen {
            display: inline-block;
            position: absolute;
            right: 14px;
            width: 50px;
            height: 15px;
            font-size: 11px;
            font-weight: 400;
            color: #666666;
            line-height: 15px;

            .icon {
                display: inline-block;
                vertical-align: bottom;
                width: 15px;
                height: 14px;
                margin-right: 5px;
            }
        }
    }

    .list,
    .listBrowsing {
        padding: 0 14px;
        height: calc(100vh - 410px);
        overflow: auto;

        .item {
            width: 100%;
            display: flex;
            margin-top: 14px;
            padding-bottom: 16px;
            border-bottom: 1px solid #dddddd;

            .left {
                width: 130px;
                height: 82px;
                border-radius: 5px;
            }

            .right {
                flex: 1;
                padding-left: 9px;

                .title {
                    width: 203px;
                    height: 20px;
                    font-size: 14px;
                    font-weight: 600;
                    color: #333333;
                    line-height: 20px;
                    white-space: nowrap;
                    overflow: hidden;
                    text-overflow: ellipsis;
                }

                .footer {
                    span {
                        height: 20px;
                        font-size: 14px;
                        font-weight: 400;
                        color: #d1323b;
                        line-height: 20px;
                    }

                    .btn {
                        float: right;
                        height: 18px;
                        font-size: 13px;
                        color: #5f7dff;
                        line-height: 18px;
                    }
                }
            }
        }
    }

    .listBrowsing {
        .item {
            .right {
                .desc {
                    width: 178px;
                    height: 18px;
                    font-size: 13px;
                    font-weight: 400;
                    color: #666666;
                    line-height: 18px;
                    white-space: nowrap;
                    overflow: hidden;
                    text-overflow: ellipsis;
                }

                .time {
                    margin-top: 5px;
                    color: #f8ab50;
                    font-size: 13px;
                }

                .t {
                    margin-top: 20px;
                }

                .footer {
                    span {
                        height: 20px;
                        font-size: 14px;
                        font-weight: 400;
                        color: #d1323b;
                        line-height: 20px;
                    }
                }
            }
        }
    }

    .list {
        .question_cirle {
            width: 100%;
            height: 20px;
            align-items: center;
            display: flex;
            justify-content: space-between;
            margin: 14px 0 10px;

            .question_cirle-continer {
                width: calc(100% - 80px);
                height: 10px;
                background: #e7ecff;
                border-radius: 5px;
            }

            .question_cirle-yes {
                width: 0;
                height: 10px;
                background: #5f7dff;
                border-radius: 5px;
            }

            span {
                width: 70px;
                height: 17px;
                font-size: 12px;
                display: inline-block;
                font-weight: 400;
                color: #999999;
                line-height: 17px;
                text-align: right;
            }
        }
        .footer {
            span {
                height: 17px;
                font-size: 12px;
                font-weight: 400;
                color: #999999;
                line-height: 17px;
            }

            .btn {
                float: right;
                height: 18px;
                font-size: 13px;
                font-weight: 500;
                color: #5f7dff;
                line-height: 18px;
            }
        }
    }
}
</style>
